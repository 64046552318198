<template>
    <ul class="nav">
        <li class="nav-item" @click="goHome">
            <router-link :class="['nav-link', activePage === 'home' ? 'active' : '']" to="/">
                <span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">cottage</span> Головна сторінка 
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :class="['nav-link', activePage === 'angebote' ? 'active' : '']" to="/angebote">
                <span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">calendar_today</span> Пропозиції 
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :class="['nav-link', activePage === 'orte' ? 'active' : '']" to="/orte">
                <span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">place</span> Зручності 
            </router-link>
        </li>
        <li v-if="!userLoggedIn" class="nav-item">
            <router-link :class="['nav-link', activePage === 'login' ? 'active' : '']" :to="{ name: 'Login'}">
                <span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">person</span> Увійдіть в систему 
            </router-link>
        </li>
        <li v-if="userLoggedIn" class="nav-item">
            <router-link :class="['nav-link', activePage === 'profile' ? 'active' : '']" :to="{ name: 'Profile'}">
                <span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">person</span> Мій профіль
            </router-link>
        </li>
        <li class="nav-item" v-click-outside="{handler: 'hideShowMoreMenu'}" @keyup.esc="hideShowMoreMenu">
            <button :class="['nav-link','no-btn', activePage === 'mehr' ? 'active' : '']" @click="toggleShowMoreMenu">
                <span class="material-icons-outlined" aria-hidden="true" data-wg-notranslate="">more_vert</span> Більше 
            </button>
            <ul v-if="showMoreMenu" class="custom-dropdown" @click="hideShowMoreMenu" @keyup.enter="hideShowMoreMenu">
              <li v-if="false" class="dropdown-navitem">
                <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/merkliste">
                    Більше пропозицій
                    <span class="material-icons ml-auto show-ltr" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                    <span class="material-icons ml-auto show-rtl" aria-hidden="true" data-wg-notranslate="">navigate_before</span>
                </router-link>
            </li>
            <li class="dropdown-navitem">
                <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/impressum">
                    Відбиток
                    <span class="material-icons ml-auto show-ltr" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                    <span class="material-icons ml-auto show-rtl" aria-hidden="true" data-wg-notranslate="">navigate_before</span>
                </router-link>
            </li>
            <li class="dropdown-navitem">
                <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/datenschutz">
                    Захист даних
                    <span class="material-icons ml-auto show-ltr" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                    <span class="material-icons ml-auto show-rtl" aria-hidden="true" data-wg-notranslate="">navigate_before</span>
                </router-link>
            </li>
            <li class="dropdown-navitem">
                <router-link :class="['dropdown-navitem__link seprator', activePage === 'mehr' ? 'active' : '']" to="/barrierefrei">
                    Доступність
                    <span class="material-icons ml-auto show-ltr" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                    <span class="material-icons ml-auto show-rtl" aria-hidden="true" data-wg-notranslate="">navigate_before</span>
                </router-link>
            </li>
            <li class="dropdown-navitem">
                <a :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" href="https://bildungsangebote-kiel.de" target="_blank">
                 Вхід для організацій 
                 <span class="material-icons ml-auto show-ltr" aria-hidden="true" data-wg-notranslate="">navigate_next</span>
                 <span class="material-icons ml-auto show-rtl" aria-hidden="true" data-wg-notranslate="">navigate_before</span>
             </a>
         </li>
         <li v-if="userLoggedIn" class="dropdown-navitem seprator">
          <button class="btn btn-hover btn-active logout-btn btn-w-icon icon-left" @click.prevent="logOut">
            <span class="material-icons-outlined inline-icon" data-wg-notranslate="">lock</span>
            <span>Вийдіть з системи</span>
        </button>
    </li>
</ul>
</li>
</ul>
</template>
<script>
  import clickOutside from '@/directives/click-outside';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
   name: 'DesktopNav',
   directives: { clickOutside },
   data() {
    return {
        showMoreMenu: false,
    }
},
computed: {
    activePage() {
        if (this.$route.path === '/') {
            return 'home';
        } else if (this.$route.path.includes('orte')) {
            return 'orte';
        } else if (this.$route.path.includes('angebote')) {
            return 'angebote';
        } else if (this.$route.path.includes('ratgeber')) {
            return 'ratgeber';
        } else if (this.$route.path.includes('login')) {
            return 'login';
        } else if (this.$route.path.includes('profile')) {
            return 'profile';
        }

        return 'mehr';
    },
    userLoggedIn() {
        if(this.$store.getters.getToken){
          return true;
      }
      return false;
  },
},
methods: {
    showLoader,
    hideLoader,
    logOut(){
        this.loader = this.showLoader(this.loader);
        this.$store.dispatch('logout');

        this.$notify({
          duration: 3500,
          title: "Erfolgreich abgemeldet!",
          text: "Du hast dich von dem Kiel.RefugeeCompass abgemeldet."
      });

        this.loader = this.hideLoader(this.loader);
        this.$router.push({name:'Home'});
    },
    goHome(){
        if(this.route == "/"){
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        else{
            this.$router.push('/')
        }
    },
    hideShowMoreMenu(){
        this.showMoreMenu = false;
    },
    toggleShowMoreMenu(){
        this.showMoreMenu = !this.showMoreMenu;
    }
}
}

</script>
<style lang="scss" scoped="">
    @import '@/scss/_variables.scss';

    header .header-bottom .navbar .nav-item{
        @media (max-width: 1056px) {
            margin-left: 45px;
        }
        @media (max-width: 1030px) {
            margin-left: 40px;
        }
        @media (max-width: 1010px) {
            margin-left: 35px;
        }
    }

    .custom-dropdown{
        display: block;
        position: absolute;
        width: 290px;
        background: #FFFFFF;
        top: 28px;
        right: 0;
        box-shadow: 0px 2px 8px 0px #00000040;
        padding: 0 30px;
        border-radius: 0px 0px 0px 40px;
    }

    .logout-btn {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 100%;
    }

    .nav-item:hover {
        color: $primary !important;
    }

    .nav-link {
        text-transform: uppercase;
        &:hover {
            color: $primary !important;

            span {
                color: $primary !important;
            }

        }
    }

    .material-icons:hover {
        text-decoration: none !important;
    }

    .dropdown-navitem__link:hover {
        text-decoration: none;
        color: $primary !important;
    }

    body[dir="rtl"] {
      .custom-dropdown {
        right: -220px;
    }
}

</style>
